@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 210 100% 98%; /* EmitIQ theme (background.paper) */

    --foreground: 222.2 84% 4.9%;

    --card: 207 24% 94%; /* EmitIQ theme */
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --info: #868686; /* EmitIQ theme */
    /* --info-foreground: 210 40% 98%; */

    --primary: 221 83% 53%; /* EmitIQ theme */
    --primary-foreground: 210 40% 98%;

    --secondary: 204 57% 29%; /* EmitIQ theme */
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 0 0% 53%; /* EmitIQ theme */
    --muted-foreground: 0 0% 0%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 14 99% 40%; /* EmitIQ theme */
    --destructive-foreground: 359 75% 33%;

    --warning: 26 99% 61%; /* EmitIQ theme */
    --warning-foreground: 24 68% 42%;

    --success: 137 100% 35%;
    --success-foreground: 122 73% 31%;

    --border: 240 2% 78%; /* EmitIQ theme */
    --input: 240 2% 78%; /* EmitIQ theme */
    --ring: 222.2 84% 4.9%;

    --scope-1: 144 54% 67%;
    --scope-2: 36 100% 68%;
    --scope-3: 270 100% 80%;
    --scope-1-foreground: 144 50% 34%;
    --scope-2-foreground: 36 86% 51%;
    --scope-3-foreground: 270 46% 48%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 183 100% 8%; /* EmitIQ theme (background.paper) */
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --info: #919090; /* EmitIQ theme */
    /* --info-foreground: 210 40% 98%; */

    --primary: 158 64% 58%; /* EmitIQ theme */
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 262 70% 58%; /* EmitIQ theme */
    --secondary-foreground: 210 40% 98%;

    --muted: 210 94% 94%; /* EmitIQ theme */
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 14 99% 40%; /* EmitIQ theme */
    --destructive-foreground: 210 40% 98%;

    --warning: 26 78% 48%; /* EmitIQ theme */

    --border: 185 9% 24%; /* EmitIQ theme */
    --input: 185 9% 24%; /* EmitIQ theme */
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.rdp-vhidden {
  @apply hidden;
}

html,
body {
  /* overflow: overlay; */
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    TTHoves-Regular,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  /* scrollbar-width: 0px; */
  /* background-color: var(--primary) */
}

/* Disable underline on links */
a {
  text-decoration: none;
  color: inherit;
}

h1 {
  @apply text-3xl leading-normal tracking-wide md:text-5xl xl:text-7xl;
}

h2 {
  @apply text-6xl leading-normal tracking-wide;
}

h3 {
  @apply text-5xl leading-normal tracking-wide;
}

h4 {
  @apply text-4xl tracking-wide;
}

h5 {
  @apply text-3xl tracking-wide;
}

h6 {
  @apply text-2xl tracking-wide;
}
.h1 {
  @apply text-3xl leading-normal tracking-wide md:text-5xl xl:text-7xl;
}

.h2 {
  @apply text-6xl leading-normal tracking-wide;
}

.h3 {
  @apply text-5xl leading-normal tracking-wide;
}

.h4 {
  @apply text-4xl tracking-wide;
}

.h5 {
  @apply text-3xl tracking-wide;
}

.h6 {
  @apply text-2xl tracking-wide;
}

.subtitle1 {
  @apply text-lg tracking-wide;
}

.subtitle2 {
  @apply text-base tracking-wide;
}

p {
  @apply text-sm tracking-wide;
}

.body1 {
  @apply text-sm tracking-wide;
}

.body2 {
  @apply text-xs tracking-wide;
}

.caption {
  @apply text-xs tracking-wide;
}

::selection {
  background: #acffd755;
}

/* TTHoves */
@font-face {
  font-family: 'TTHoves-Bold-Italic';
  src:
    url('/fonts/TTHoves/TTHoves-Bold-Italic.woff2') format('woff2'),
    url('/fonts/TTHoves/TTHoves-Bold-Italic.woff') format('woff');
}

@font-face {
  font-family: 'TTHoves-Bold';
  src:
    url('/fonts/TTHoves/TTHoves-Bold.woff2') format('woff2'),
    url('/fonts/TTHoves/TTHoves-Bold.woff') format('woff');
}

@font-face {
  font-family: 'TTHoves-Italic';
  src:
    url('/fonts/TTHoves/TTHoves-Italic.woff2') format('woff2'),
    url('/fonts/TTHoves/TTHoves-Italic.woff') format('woff');
}

@font-face {
  font-family: 'TTHoves-Medium-Italic';
  src:
    url('/fonts/TTHoves/TTHoves-Medium-Italic.woff2') format('woff2'),
    url('/fonts/TTHoves/TTHoves-Medium-Italic.woff') format('woff');
}

@font-face {
  font-family: 'TTHoves-Medium';
  src:
    url('/fonts/TTHoves/TTHoves-Medium.woff2') format('woff2'),
    url('/fonts/TTHoves/TTHoves-Medium.woff') format('woff');
}

@font-face {
  font-family: 'TTHoves-Regular';
  src:
    url('/fonts/TTHoves/TTHoves-Regular.woff2') format('woff2'),
    url('/fonts/TTHoves/TTHoves-Regular.woff') format('woff');
}

/* Effra */
@font-face {
  font-family: 'Effra-Bold-Italic';
  src: url('/fonts/Effra/Effra-Bold-Italic.ttf');
}

@font-face {
  font-family: 'Effra-Bold';
  src: url('/fonts/Effra/Effra-Bold.ttf');
}

@font-face {
  font-family: 'Effra-XBold-Italic';
  src: url('/fonts/Effra/Effra-XBold-Italic.ttf');
}

@font-face {
  font-family: 'Effra-XBold';
  src: url('/fonts/Effra/Effra-XBold.ttf');
}

/* Manrope */
@font-face {
  font-family: 'Manrope';
  src: url('/fonts/Manrope/Manrope-Bold.ttf');
}

@font-face {
  font-family: 'Manrope-ExtraBold';
  src: url('/fonts/Manrope/Manrope-ExtraBold.ttf');
}

@font-face {
  font-family: 'Manrope-ExtraLight';
  src: url('/fonts/Manrope/Manrope-ExtraLight.ttf');
}

@font-face {
  font-family: 'Manrope-Light';
  src: url('/fonts/Manrope/Manrope-Light.ttf');
}

@font-face {
  font-family: 'Manrope-Medium';
  src: url('/fonts/Manrope/Manrope-Medium.ttf');
}

@font-face {
  font-family: 'Manrope-Regular';
  src: url('/fonts/Manrope/Manrope-Regular.ttf');
}

@font-face {
  font-family: 'Manrope-SemiBold';
  src: url('/fonts/Manrope/Manrope-SemiBold.ttf');
}

/* Normal */
@font-face {
  font-family: 'Normal-ExtraLight';
  src: url('/fonts/Normal/TTF/Normal-ExtraLight.ttf');
}

@font-face {
  font-family: 'Normal-Light';
  src: url('/fonts/Normal/TTF/Normal-Light.ttf');
}

@font-face {
  font-family: 'Normal-Regular';
  src: url('/fonts/Normal/TTF/Normal-Regular.ttf');
}

@font-face {
  font-family: 'Normal-Medium';
  src: url('/fonts/Normal/TTF/Normal-Medium.ttf');
}

@font-face {
  font-family: 'Normal-Bold';
  src: url('/fonts/Normal/TTF/Normal-Bold.ttf');
}

@font-face {
  font-family: 'Normal-ExtraBold';
  src: url('/fonts/Normal/TTF/Normal-ExtraBold.ttf');
}

/* GreycliffCF */
@font-face {
  font-family: 'GreycliffCF-Bold';
  src:
    url('/fonts/Greycliff/Webfont/GreycliffCF-Bold.woff2')
      format('woff2'),
    url('/fonts/Greycliff/Webfont/GreycliffCF-Bold.woff')
      format('woff');
}

@font-face {
  font-family: 'GreycliffCF-BoldOblique';
  src:
    url('/fonts/Greycliff/Webfont/GreycliffCF-BoldOblique.woff2')
      format('woff2'),
    url('/fonts/Greycliff/Webfont/GreycliffCF-BoldOblique.woff')
      format('woff');
}

@font-face {
  font-family: 'GreycliffCF-Heavy';
  src:
    url('/fonts/Greycliff/Webfont/GreycliffCF-Heavy.woff2')
      format('woff2'),
    url('/fonts/Greycliff/Webfont/GreycliffCF-Heavy.woff')
      format('woff');
}

@font-face {
  font-family: 'GreycliffCF-HeavyOblique';
  src:
    url('/fonts/Greycliff/Webfont/GreycliffCF-HeavyOblique.woff2')
      format('woff2'),
    url('/fonts/Greycliff/Webfont/GreycliffCF-HeavyOblique.woff')
      format('woff');
}

@font-face {
  font-family: 'GreycliffCF-Light';
  src:
    url('/fonts/Greycliff/Webfont/GreycliffCF-Light.woff2')
      format('woff2'),
    url('/fonts/Greycliff/Webfont/GreycliffCF-Light.woff')
      format('woff');
}

@font-face {
  font-family: 'GreycliffCF-LightOblique';
  src:
    url('/fonts/Greycliff/Webfont/GreycliffCF-LightOblique.woff2')
      format('woff2'),
    url('/fonts/Greycliff/Webfont/GreycliffCF-LightOblique.woff')
      format('woff');
}

@font-face {
  font-family: 'GreycliffCF-Medium';
  src:
    url('/fonts/Greycliff/Webfont/GreycliffCF-Medium.woff2')
      format('woff2'),
    url('/fonts/Greycliff/Webfont/GreycliffCF-Medium.woff')
      format('woff');
}

@font-face {
  font-family: 'GreycliffCF-MediumOblique';
  src:
    url('/fonts/Greycliff/Webfont/GreycliffCF-MediumOblique.woff2')
      format('woff2'),
    url('/fonts/Greycliff/Webfont/GreycliffCF-MediumOblique.woff')
      format('woff');
}

@font-face {
  font-family: 'GreycliffCF-Regular';
  src:
    url('/fonts/Greycliff/Webfont/GreycliffCF-Regular.woff2')
      format('woff2'),
    url('/fonts/Greycliff/Webfont/GreycliffCF-Regular.woff')
      format('woff');
}

@font-face {
  font-family: 'GreycliffCF-RegularOblique';
  src:
    url('/fonts/Greycliff/Webfont/GreycliffCF-RegularOblique.woff2')
      format('woff2'),
    url('/fonts/Greycliff/Webfont/GreycliffCF-RegularOblique.woff')
      format('woff');
}

@font-face {
  font-family: 'GreycliffCF-Thin';
  src:
    url('/fonts/Greycliff/Webfont/GreycliffCF-Thin.woff2')
      format('woff2'),
    url('/fonts/Greycliff/Webfont/GreycliffCF-Thin.woff')
      format('woff');
}

@font-face {
  font-family: 'GreycliffCF-ThinOblique';
  src:
    url('/fonts/Greycliff/Webfont/GreycliffCF-ThinOblique.woff2')
      format('woff2'),
    url('/fonts/Greycliff/Webfont/GreycliffCF-ThinOblique.woff')
      format('woff');
}

/* Switzer */
@font-face {
  font-family: 'Switzer-Black';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-Black.ttf');
}

@font-face {
  font-family: 'Switzer-BlackItalic';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-BlackItalic.ttf');
}

@font-face {
  font-family: 'Switzer-Bold';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-Bold.ttf');
}

@font-face {
  font-family: 'Switzer-BoldItalic';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-BoldItalic.ttf');
}

@font-face {
  font-family: 'Switzer-Extrabold';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-Extrabold.ttf');
}

@font-face {
  font-family: 'Switzer-ExtraboldItalic';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-ExtraboldItalic.ttf');
}

@font-face {
  font-family: 'Switzer-Extralight';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-Extralight.ttf');
}

@font-face {
  font-family: 'Switzer-ExtralightItalic';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-ExtralightItalic.ttf');
}

@font-face {
  font-family: 'Switzer-Italic';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-Italic.ttf');
}

@font-face {
  font-family: 'Switzer-Light';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-Light.ttf');
}

@font-face {
  font-family: 'Switzer-LightItalic';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-LightItalic.ttf');
}

@font-face {
  font-family: 'Switzer-Medium';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-Medium.ttf');
}

@font-face {
  font-family: 'Switzer-MediumItalic';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-MediumItalic.ttf');
}

@font-face {
  font-family: 'Switzer-Regular';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-Regular.ttf');
}

@font-face {
  font-family: 'Switzer-Semibold';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-Semibold.ttf');
}

@font-face {
  font-family: 'Switzer-SemiboldItalic';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-SemiboldItalic.ttf');
}

@font-face {
  font-family: 'Switzer-Thin';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-Thin.ttf');
}

@font-face {
  font-family: 'Switzer-ThinItalic';
  src: url('/fonts/Switzer/Fonts/WEB/fonts/Switzer-ThinItalic.ttf');
}

/* Outfit */
@font-face {
  font-family: 'Outfit-Black';
  src: url('/fonts/Outfit/Fonts/WEB/fonts/Outfit-Black.ttf');
}

@font-face {
  font-family: 'Outfit-Bold';
  src: url('/fonts/Outfit/Fonts/WEB/fonts/Outfit-Bold.ttf');
}

@font-face {
  font-family: 'Outfit-ExtraBold';
  src: url('/fonts/Outfit/Fonts/WEB/fonts/Outfit-ExtraBold.ttf');
}

@font-face {
  font-family: 'Outfit-ExtraLight';
  src: url('/fonts/Outfit/Fonts/WEB/fonts/Outfit-ExtraLight.ttf');
}

@font-face {
  font-family: 'Outfit-Light';
  src: url('/fonts/Outfit/Fonts/WEB/fonts/Outfit-Light.ttf');
}

@font-face {
  font-family: 'Outfit-Medium';
  src: url('/fonts/Outfit/Fonts/WEB/fonts/Outfit-Medium.ttf');
}

@font-face {
  font-family: 'Outfit-Regular';
  src: url('/fonts/Outfit/Fonts/WEB/fonts/Outfit-Regular.ttf');
}

@font-face {
  font-family: 'Outfit-SemiBold';
  src: url('/fonts/Outfit/Fonts/WEB/fonts/Outfit-SemiBold.ttf');
}

@font-face {
  font-family: 'Outfit-Thin';
  src: url('/fonts/Outfit/Fonts/WEB/fonts/Outfit-Thin.ttf');
}

/* TTNorms */
@font-face {
  font-family: 'TTNorms-Black';
  src: url('/fonts/TTNorms/TTNorms-Black.otf');
}

@font-face {
  font-family: 'TTNorms-BlackItalic';
  src: url('/fonts/TTNorms/TTNorms-BlackItalic.otf');
}

@font-face {
  font-family: 'TTNorms-Bold';
  src: url('/fonts/TTNorms/TTNorms-Bold.otf');
}

@font-face {
  font-family: 'TTNorms-BoldItalic';
  src: url('/fonts/TTNorms/TTNorms-BoldItalic.otf');
}

@font-face {
  font-family: 'TTNorms-ExtraBold';
  src: url('/fonts/TTNorms/TTNorms-ExtraBold.otf');
}

@font-face {
  font-family: 'TTNorms-ExtraBoldItalic';
  src: url('/fonts/TTNorms/TTNorms-ExtraBoldItalic.otf');
}

@font-face {
  font-family: 'TTNorms-ExtraLight';
  src: url('/fonts/TTNorms/TTNorms-ExtraLight.otf');
}

@font-face {
  font-family: 'TTNorms-ExtraLightItalic';
  src: url('/fonts/TTNorms/TTNorms-ExtraLightItalic.otf');
}

@font-face {
  font-family: 'TTNorms-Heavy';
  src: url('/fonts/TTNorms/TTNorms-Heavy.otf');
}

@font-face {
  font-family: 'TTNorms-HeavyItalic';
  src: url('/fonts/TTNorms/TTNorms-HeavyItalic.otf');
}

@font-face {
  font-family: 'TTNorms-Italic';
  src: url('/fonts/TTNorms/TTNorms-Italic.otf');
}

@font-face {
  font-family: 'TTNorms-Light';
  src: url('/fonts/TTNorms/TTNorms-Light.otf');
}

@font-face {
  font-family: 'TTNorms-LightItalic';
  src: url('/fonts/TTNorms/TTNorms-LightItalic.otf');
}

@font-face {
  font-family: 'TTNorms-Medium';
  src: url('/fonts/TTNorms/TTNorms-Medium.otf');
}

@font-face {
  font-family: 'TTNorms-MediumItalic';
  src: url('/fonts/TTNorms/TTNorms-MediumItalic.otf');
}

@font-face {
  font-family: 'TTNorms-Regular';
  src: url('/fonts/TTNorms/TTNorms-Regular.otf');
}

@font-face {
  font-family: 'TTNorms-Thin';
  src: url('/fonts/TTNorms/TTNorms-Thin.otf');
}

@font-face {
  font-family: 'TTNorms-ThinItalic';
  src: url('/fonts/TTNorms/TTNorms-ThinItalic.otf');
}

#__next {
  width: 100%;
  height: 100%;
}

.collapse {
  display: none;
}

.mapboxgl-popup-close-button {
  padding-block: 6px;
  padding-inline: 12px;
  font-size: large;
}
/* Timeline component */
.timeline-left-shadow::before {
  content: '';
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -20;
  mask-image: 'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%)';
}

select.simple-select {
  /* make arrow and background */

  background: linear-gradient(45deg, transparent 50%, black 50%),
    linear-gradient(135deg, black 50%, transparent 50%),
    linear-gradient(to right, #f9fafc, #f9fafc);
  background-position:
    calc(100% - 21px) calc(1em + 2px),
    calc(100% - 16px) calc(1em + 2px),
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;

  /* reset */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
