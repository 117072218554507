/* customized root.css file from @graphiql/react-graphiql - HSL valued colors */
/* These variables are the only official way to customize the graphiql theme */

/* a very simple box-model reset, intentionally does not include pseudo elements */
.graphiql-container * {
  box-sizing: border-box;
}

.graphiql-container,
.CodeMirror-info,
.CodeMirror-lint-tooltip,
reach-portal {
  /* Colors */
  --color-primary: 320, 95%, 43%;
  --color-secondary: 242, 51%, 61%;
  --color-tertiary: 188, 100%, 36%;
  --color-info: 208, 100%, 46%;
  --color-success: 158, 60%, 42%;
  --color-warning: 36, 100%, 41%;
  --color-error: 13, 93%, 58%;
  --color-neutral: 219, 28%, 32%;
  --color-base: 219, 28%, 100%;

  /* Color alpha values */
  --alpha-secondary: 0.76;
  --alpha-tertiary: 0.5;
  --alpha-background-heavy: 0.15;
  --alpha-background-medium: 0.1;
  --alpha-background-light: 0.07;

  /* Font */
  --font-family: 'Roboto', sans-serif;
  --font-family-mono: 'Fira Code', monospace;
  --font-size-hint: calc(12rem / 16);
  --font-size-inline-code: calc(13rem / 16);
  --font-size-body: calc(15rem / 16);
  --font-size-h4: calc(18rem / 16);
  --font-size-h3: calc(22rem / 16);
  --font-size-h2: calc(29rem / 16);
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --line-height: 1.5;

  /* Spacing */
  --px-2: 2px;
  --px-4: 4px;
  --px-6: 6px;
  --px-8: 8px;
  --px-10: 10px;
  --px-12: 12px;
  --px-16: 16px;
  --px-20: 20px;
  --px-24: 24px;

  /* Border radius */
  --border-radius-2: 2px;
  --border-radius-4: 4px;
  --border-radius-8: 8px;
  --border-radius-12: 12px;

  /* Popover styles (tooltip, dialog, etc) */
  --popover-box-shadow: 0px 6px 20px rgba(59, 76, 106, 0.13),
    0px 1.34018px 4.46726px rgba(59, 76, 106, 0.0774939),
    0px 0.399006px 1.33002px rgba(59, 76, 106, 0.0525061);
  --popover-border: none;

  /* Layout */
  --sidebar-width: 60px;
  --toolbar-width: 40px;
  --session-header-height: 51px;
}

@media (prefers-color-scheme: dark) {
  body:not(.graphiql-light) .graphiql-container,
  body:not(.graphiql-light) .CodeMirror-info,
  body:not(.graphiql-light) .CodeMirror-lint-tooltip,
  body:not(.graphiql-light) reach-portal {
    --color-primary: 338, 100%, 67%;
    --color-secondary: 243, 100%, 77%;
    --color-tertiary: 188, 100%, 44%;
    --color-info: 208, 100%, 72%;
    --color-success: 158, 100%, 42%;
    --color-warning: 30, 100%, 80%;
    --color-error: 13, 100%, 58%;
    --color-neutral: 219, 29%, 78%;
  
    /* background.default HSL value */
    --color-base: 228, 7.94%, 12.35% !important;

    --popover-box-shadow: none;
    --popover-border: 1px solid hsl(var(--color-neutral));
  }
}

body.graphiql-dark .graphiql-container,
body.graphiql-dark .CodeMirror-info,
body.graphiql-dark .CodeMirror-lint-tooltip,
body.graphiql-dark reach-portal {
  /* primary.main HSL values*/
  --color-primary: 151.09, 63.72%, 57.84% !important;
  --color-secondary: 243, 100%, 77%;
  --color-tertiary: 188, 100%, 44%;
  --color-info: 208, 100%, 72%;
  --color-success: 158, 100%, 42%;
  --color-warning: 30, 100%, 80%;
  --color-error: 13, 100%, 58%;
  --color-neutral: 219, 29%, 78%;

  /* background.default HSL value */
  /* !important is used here because it is the simplest way to make
    a one-time, dead-ended change to the background-color. This use case 
    avoids the pitfalls of the !important anti-pattern. It will not affect
    accessibility (text size, highlighted colors) and is not intended
    to be used in any inherited case outside the graphiql view. A solution using 
    higher CSS specificity is likely possible but much messier and not neccesary. */
  --color-base: 228, 7.94%, 12.35% !important;

  --popover-box-shadow: none;
  --popover-border: 1px solid hsl(var(--color-neutral));
}

/* .graphiql-container,
.CodeMirror-info,
.CodeMirror-lint-tooltip,
reach-portal {
  &,
  &:is(button) {
    color: hsla(var(--color-neutral), 1);
    font-family: var(--font-family);
    font-size: var(--font-size-body);
    font-weight: var(----font-weight-regular);
    line-height: var(--line-height);
  }

  & input {
    color: hsla(var(--color-neutral), 1);
    font-family: var(--font-family);
    font-size: var(--font-size-caption);

    &::placeholder {
      color: hsla(var(--color-neutral), var(--alpha-secondary));
    }
  }

  & a {
    color: hsl(var(--color-primary));

    &:focus {
      outline: hsl(var(--color-primary)) auto 1px;
    }
  }
} */