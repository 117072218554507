#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  top: 0;
  left: 0;
  height: 2px;
  width: 100%;
  position: fixed;
  z-index: 9999;
  background: #00ab55;
  box-shadow: 0 0 2px #00ab55;
}

#nprogress .peg {
  right: 0;
  opacity: 1;
  width: 100px;
  height: 100%;
  display: block;
  position: absolute;
  transform: rotate(3deg) translate(0px, -4px);
  box-shadow: 0 0 10px #00ab55, 0 0 5px #00ab55;
}
